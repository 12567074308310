import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TopPositionCard, TopPositionCardText, PositionCardsContainer, PositionCards, FitnessTopContainer, FirstPositionImage, CardWrapper } from './style';
import { Waiting } from '../../components';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { ImageUrl } from '../../utils/constants';
import { commaSeperator, rankingDashboard, convertMilesToKm } from '../../utils/methods';
import { withTranslation } from 'react-i18next';

class FitnessActivityData extends Component {

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  leaderboardLimit = () => {
    const { getTopFitnessLeaderboard, companyId, userLimit, pageNumber, updateLimit } = this.props;
    updateLimit();
    let pageCount;
    pageCount = pageNumber + 1;
    getTopFitnessLeaderboard(companyId, pageCount, userLimit);
  }

  leaderboardAllPosition = (fitnessActivityData) => {
    const { activeBuddiesPoints, t } = this.props;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      activeBuddiesPoints === "5" ?
        fitnessActivityData.map((step, index) =>
          (index > 2 && <PositionCards key={index}>
            <div>
              <div><img onError={this.fallBack} src={`${ImageUrl}/${step.profile_image}`} alt="Profile" /></div>
              <div>
                <div>{t(index + 1 + rankingDashboard(index))}</div>
                <div>{step.name}</div>
              </div>
            </div>

            <div>
              <div>{step.total_steps ? commaSeperator(step.total_steps) : 0}</div>&nbsp;
              <div>{t("Steps")}</div>
            </div>
          </PositionCards>)) :
        activeBuddiesPoints === "6" ?
          fitnessActivityData.map((calory, index) =>
            (index > 2 && <PositionCards key={index}>
              <div>
                <div><img onError={this.fallBack} src={`${ImageUrl}/${calory.profile_image}`} alt="Profile" /></div>
                <div>
                  <div>{t(index + 1 + rankingDashboard(index))}</div>
                  <div>{calory.name}</div>
                </div>
              </div>

              <div>
                <div>{calory.total_calories ? commaSeperator(calory.total_calories) : 0}</div>&nbsp;
                <div>{t("Cals")}</div>
              </div>
            </PositionCards>)) :
          fitnessActivityData.map((data, index) =>
            (index > 2 && <PositionCards key={index}>
              <div>
                <div><img onError={this.fallBack} src={`${ImageUrl}/${data.profile_image}`} alt="Profile" /></div>
                <div>
                  <div>{ t(index + 1 + rankingDashboard(index)) }</div>
                  <div>{data.name}</div>
                </div>
              </div>

              <div>
                <div>{data.total_distance ?DistanceUnit === "KILOMETER"? commaSeperator(convertMilesToKm(data.total_distance)): commaSeperator(data.total_distance) : 0}</div>&nbsp;
                <div>{DistanceUnit === "KILOMETER"?t("Km"):t("Miles")}</div>
              </div>
            </PositionCards>))
    )
  }

  onScroll = (e) => {
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) === Math.round(e.target.clientHeight);
    if (bottom) {
      this.leaderboardLimit()
    }
  };

  render() {
    const { activeBuddiesPoints, distanceLeaderboard, stepsLeaderboard, caloriesLeaderboard, t } = this.props;

    if (activeBuddiesPoints === "5" && isEmpty(stepsLeaderboard)) {
      return <Waiting />
    } else if (activeBuddiesPoints === "6" && isEmpty(caloriesLeaderboard)) {
      return <Waiting />
    } else if (activeBuddiesPoints === "7" && isEmpty(distanceLeaderboard)) {
      return <Waiting />
    }
    const fitnessActivityData = activeBuddiesPoints === "5" && stepsLeaderboard && stepsLeaderboard.length ? stepsLeaderboard : activeBuddiesPoints === "6" && caloriesLeaderboard && caloriesLeaderboard.length ? caloriesLeaderboard : activeBuddiesPoints === "7" && distanceLeaderboard && distanceLeaderboard.length ? distanceLeaderboard : null;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      <React.Fragment>
        {!isUndefined(fitnessActivityData) ?
          <CardWrapper onScroll={this.onScroll}>
            <TopPositionCard margin="1">
              <TopPositionCardText margin="1">
                <div>{activeBuddiesPoints === "5" ? t("Steps Completed") : activeBuddiesPoints === "6" ? t("Calories Burned") : t("Distance Traveled")}</div>
              </TopPositionCardText>
              <FitnessTopContainer>
                <FirstPositionImage>
                  <img onError={this.fallBack} src={'/public/images/behaviorChallenges/winner.png'} alt={'winner'} />
                </FirstPositionImage>
                {!isUndefined(fitnessActivityData[0]) ? <PositionCards color="1" background="rgba(246, 180, 121, 1)">
                  <div>
                    <div>
                      {<img onError={this.fallBack} src={`${ImageUrl}/${fitnessActivityData[0].profile_image}`} alt="Profile" />}
                    </div>
                    <div>
                      <div>{t("1st")}</div>
                      <div>{fitnessActivityData[0].name}</div>
                    </div>
                  </div>
                  <div>
                    <div>{activeBuddiesPoints === "5" ? fitnessActivityData[0].total_steps ? commaSeperator(fitnessActivityData[0].total_steps) : 0 : activeBuddiesPoints === "6" ? fitnessActivityData[0].total_calories ? commaSeperator(fitnessActivityData[0].total_calories) : 0 : fitnessActivityData[0].total_distance ?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(fitnessActivityData[0].total_distance)) :commaSeperator(fitnessActivityData[0].total_distance) : 0}</div>&nbsp;
                    <div>{activeBuddiesPoints === "5" ? t("Steps") : activeBuddiesPoints === "6" ? t("Cals") : DistanceUnit === "KILOMETER"?t("Km"):t("Miles")}</div>
                  </div>
                </PositionCards> : null}
                {!isUndefined(fitnessActivityData[1]) ? <PositionCards color="1" background="rgba(159, 201, 137, 1)">
                  <div>
                    <div>
                      {<img onError={this.fallBack} src={`${ImageUrl}/${fitnessActivityData[1].profile_image}`} alt="Profile" />}
                    </div>
                    <div>
                      <div>{t("2nd")}</div>
                      <div>{fitnessActivityData[1].name}</div>
                    </div>
                  </div>

                  <div>
                    <div>{activeBuddiesPoints === "5" ? fitnessActivityData[1].total_steps ? commaSeperator(fitnessActivityData[1].total_steps) : 0 : activeBuddiesPoints === "6" ? fitnessActivityData[1].total_calories ? commaSeperator(fitnessActivityData[1].total_calories) : 0 : fitnessActivityData[1].total_distance ?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(fitnessActivityData[1].total_distance)): commaSeperator(fitnessActivityData[1].total_distance) : 0}</div>&nbsp;
                    <div>{activeBuddiesPoints === "5" ? t("Steps") : activeBuddiesPoints === "6" ? t("Cals") : DistanceUnit === "KILOMETER"?t("Km"):t("Miles")}</div>
                  </div>
                </PositionCards> : null}
                {!isUndefined(fitnessActivityData[2]) ? <PositionCards color="1" background="rgba(253, 113, 117, 1)">
                  <div>
                    <div>
                      {<img onError={this.fallBack} src={`${ImageUrl}/${fitnessActivityData[2].profile_image}`} alt="Profile" />}
                    </div>
                    <div>
                      <div>{t("3rd")}</div>
                      <div>{fitnessActivityData[2].name}</div>
                    </div>
                  </div>

                  <div>
                    <div>{activeBuddiesPoints === "5" ? fitnessActivityData[2].total_steps ? commaSeperator(fitnessActivityData[2].total_steps) : 0 : activeBuddiesPoints === "6" ? fitnessActivityData[2].total_calories ? commaSeperator(fitnessActivityData[2].total_calories) : 0 : fitnessActivityData[2].total_distance ?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(fitnessActivityData[2].total_distance)): commaSeperator(fitnessActivityData[2].total_distance) : 0}</div>&nbsp;
                    <div>{activeBuddiesPoints === "5" ? t("Steps") : activeBuddiesPoints === "6" ? t("Cals") : DistanceUnit === "KILOMETER"?t("Km"):t("Miles")}</div>
                  </div>
                </PositionCards> : null}
              </FitnessTopContainer>
            </TopPositionCard>
            <PositionCardsContainer >
              {this.leaderboardAllPosition(fitnessActivityData)}
            </PositionCardsContainer>
          </CardWrapper> : null}
      </React.Fragment>
    )
  }
}

FitnessActivityData.propTypes = {
  history: PropTypes.object.isRequired,
  fitnessLeaderboard: PropTypes.object,
  activeBuddiesPoints: PropTypes.string,
  leaderboardLimit: PropTypes.func,
  companyId: PropTypes.number,
  userLimit: PropTypes.number,
  updateLimit: PropTypes.func,
  pageNumber: PropTypes.number,
  getTopFitnessLeaderboard: PropTypes.func,
  distanceLeaderboard: PropTypes.object,
  stepsLeaderboard: PropTypes.object,
  caloriesLeaderboard: PropTypes.object,
  t: PropTypes.func
};

export default (withTranslation()(FitnessActivityData));